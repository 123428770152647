import Grid from '@mui/material/Grid';

import {Accordion} from '../Accordion/Accordion';
import {OfferDetailsComment} from './OfferDetailsComment';
import {OfferDetailsHeader} from './OfferDetailsHeader';
import {OfferDetailsRow} from './OfferDetailsRow';
import {OfferInfo} from './OfferInfo';
import {
    IWarrantiesAcronyms,
    IWarrantiesAmount,
    IWarrantiesDetails,
    IWarrantiesSection,
    WarrantyAmountType
} from "../../model/Waranties.model";
import {OfferDetailLabel} from "./OfferDetailLabel";
import {NeedHospitalIcon} from "../Icon/NeedHospitalIcon";
import {NeedRoutineCareIcon} from "../Icon/NeedRoutineCareIcon";
import {NeedDentalIcon} from "../Icon/NeedDentalIcon";
import {NeedOpticalIcon} from "../Icon/NeedOpticalIcon";
import {NeedAudioIcon} from "../Icon/NeedAudioIcon";
import {NeedCureIcon} from "../Icon/NeedCureIcon";
import {NeedSoftMedicineIcon} from "../Icon/NeedSoftMedicineIcon";
import {NeedAssistanceIcon} from "../Icon/NeedAssistanceIcon";
import {NeedServiceIcon} from "../Icon/NeedServiceIcon";
import {sortByOrder} from "../../utils/sortUtils";
import {useWarantiesContext} from "./WarrantiesContext";


interface Props {
    plateformStyle: any;
    codeOffer: string;
    formulesDetailsLength: number;
    formuleCenterId: number;
    formuleLeftId?: number;
    formuleRightId?: number;
    formuleSelectedId?: number;
    formuleRecommendedId?: number;
    isOpen: boolean;
    warrantiesSection: IWarrantiesSection;
}


export const OfferDetailsBaseWarranties = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;
    const iconStyle: any = plateformStyle.components.Icon;

    const [, ,warrantiesAcronyms] = useWarantiesContext();


    const getInfoFromWarranty = (warrantyAmount?: IWarrantiesAmount): IWarrantiesAcronyms | undefined => {
        if (warrantyAmount?.amountAcronym === undefined) {
            return undefined;
        }
        return warrantiesAcronyms.find(acronym => acronym.acronym === warrantyAmount?.amountAcronym);
    }

    const getRowContent = (warrantiesFormulas: IWarrantiesDetails[], codeFormule?: number) => {

        let contentEmpty = "-";

        if (!codeFormule)
            return;

        const warrantyFormula = warrantiesFormulas.find(warranty => warranty.formulaId === codeFormule.toString());

        let cellContent: (string | IWarrantiesAcronyms)[] = [];
        if (warrantyFormula) {

            warrantyFormula.amounts.forEach((amount, index) => {
                if (index > 0) {
                    cellContent.push(" + ");
                }

                if (amount.amount) {
                    const symbol = amount.amountType === WarrantyAmountType.CURRENCY ? " €" : "%";
                    cellContent.push(amount.amount.toLocaleString() + symbol);
                }

                const info = getInfoFromWarranty(amount);
                if (info) {
                    cellContent.push(" ");
                    cellContent.push(info);
                }

                if (amount.mr) {
                    const mrAcronym = warrantiesAcronyms.find(acronym => acronym.acronym === 'MR');
                    if (mrAcronym) {
                        cellContent.push(" - ");
                        cellContent.push(mrAcronym);
                    }
                }

                if (amount.description) {
                    cellContent.push(" ");
                    cellContent.push(amount.description);
                }
            });
        }

        return <OfferDetailLabel plateformStyle={props.plateformStyle}
                                 content={warrantyFormula ? cellContent : [contentEmpty]}/>
    }

    const formatLabel = (title: string): JSX.Element[] => {
        let content: JSX.Element[];

        let acronyms = warrantiesAcronyms.map(acronym => acronym.acronym);
        let tmp = title.split(new RegExp(`(${acronyms.join('|')})`, "g"))

        content = tmp.map((_: any) => {
            if (acronyms.includes(_)) {
                const acronymWarranty = warrantiesAcronyms.find(acronym => acronym.acronym === _);
                if (acronymWarranty) {
                    return <OfferInfo plateformStyle={props.plateformStyle}
                                      info={acronymWarranty}/>
                }
            }

            return (<>{_}</>);
        });

        return content;
    }

    const getIcon = (sectionTile: string): JSX.Element => {
        switch (sectionTile) {
            case 'Hospitalisation':
            default:
                return <NeedHospitalIcon color={iconStyle.color}/>;
            case 'Soins courants':
                return <NeedRoutineCareIcon color={iconStyle.color} />;
            case 'Dentaire':
                return <NeedDentalIcon color={iconStyle.color}/>;
            case 'Optique':
                return <NeedOpticalIcon color={iconStyle.color}/>;
            case 'Aides Auditives':
                return <NeedAudioIcon color={iconStyle.color}/>;
            case 'Cures':
                return <NeedCureIcon color={iconStyle.color}/>;
            case "Prévention non prise en charge par l'Assurance Maladie":
                return <NeedSoftMedicineIcon color={iconStyle.color}/>;
            case 'Assistance (FILASSISTANCE)':
                return <NeedAssistanceIcon color={iconStyle.color}/>;
            case 'Service (MEDECIN DIRECT)':
                return <NeedServiceIcon color={iconStyle.color}/>;
        }
    }


    return (
        <Accordion
            plateformStyle={plateformStyle}
            label={props.warrantiesSection.sectionTitle}
            icon={getIcon(props.warrantiesSection.sectionTitle)}
            isOpen={props.isOpen}>

            <Grid
                container
                sx={{
                    mt: 1,
                    pr: {
                        lg: props.formulesDetailsLength > 3 ? 7 : 0,
                        md: props.formulesDetailsLength > 3 ? 12 : 0,
                        xs: 0
                    }
                }}>
                {props.warrantiesSection.comment &&
                    <OfferDetailsComment
                        plateformStyle={plateformStyle}
                        comment={formatLabel(props.warrantiesSection.comment)}/>
                }

                {props.warrantiesSection.subSections.sort((a, b) => {
                    return sortByOrder(a.order, b.order);
                }).map((_, id) => {
                    let title = _.subSectionTitle;
                    let content: JSX.Element[] | undefined;

                    if (title) {
                        content = formatLabel(title);
                    }

                    return (<>
                        {content && <OfferDetailsHeader
                            plateformStyle={plateformStyle}
                            title={content}/>}

                        {
                            _.warranties.sort((a, b) => {
                                return sortByOrder(a.order, b.order);
                            }).map(_ => {
                                let contentCenter = getRowContent(_.formulas, props.formuleCenterId);
                                let contentLeft = getRowContent(_.formulas, props.formuleLeftId);
                                let contentRight = getRowContent(_.formulas, props.formuleRightId);
                                return (contentCenter &&
                                    <OfferDetailsRow
                                        plateformStyle={plateformStyle}
                                        title={formatLabel(_.label)}
                                        formuleCenter={contentCenter}
                                        formuleLeft={contentLeft}
                                        formuleRight={contentRight}
                                        formuleRecommended={props.formuleSelectedId === props.formuleLeftId ? "left" :
                                            props.formuleSelectedId === props.formuleCenterId ? "center" :
                                                props.formuleSelectedId === props.formuleRightId ? "right" : undefined}
                                        formulesDetailsLength={props.formulesDetailsLength}/>
                                );
                            })
                        }
                    </>);
                })}
            </Grid>

        </Accordion>
    )
}
