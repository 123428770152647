import TagManager, { DataLayerArgs } from 'react-gtm-module';


const stepLabelGTM = [
    {
        step: 0,
        innerStep: 0,
        label: "offer_comparison_user"
    },
    {
        step: 0,
        innerStep: 0,
        label: "Step1_ChoixOffre"
    },
    {
        step: 1,
        innerStep: 0,
        label: "Step2_Informations"
    },
    {
        step: 1,
        innerStep: 2,
        label: "Step2.5_Iban"
    },
    {
        step: 2,
        innerStep: 3,
        label: "Step3_Signature"
    },
    {
        step: 3,
        innerStep: 0,
        label: "Step4_Confirmation"
    }
]


export const sendStepGTM = (
    isFormStart: boolean,
    step: number,
    innerStep: number,
    uuid: string,
    offerCode: string,
    plateformeName: string,
    plateformeGSMC: boolean,
    formuleLabel?: string,
    formulePrice?: number) => {
    let steps : any[] = [];
    let stepInfosGTM;

    // Get all previous steps if form start.
    if (isFormStart) {
        steps = stepLabelGTM
            .filter(_ => _.label !== undefined)
            .filter(_ => step > _.step || (step === _.step && innerStep > _.innerStep))
    }

    // Get current step.
    stepInfosGTM = stepLabelGTM.find(_ => _.step === step && _.innerStep === innerStep);
    if (stepInfosGTM?.label !== undefined)
        steps.push(stepInfosGTM);

    // Filter out event companion for none GSMC plateforme.
    if (plateformeGSMC)
        steps.filter(_ => _.label !== "offer_comparison_user")

    // Add tag to data layer.
    steps.forEach(_ => {

        let tagManagerArgs: DataLayerArgs;

        if (_.label === "offer_comparison_user") {
            tagManagerArgs = {
                dataLayer: {
                    event: _.label,
                    comparison_source: plateformeName
                }
            }
        }
        else {
            tagManagerArgs = {
                dataLayer: {
                    event: _.label,
                    formPage: _.label,
                    landingPage: plateformeName,
                    transactionId: uuid,
                    transactionAffiliation: formuleLabel || undefined,
                    transactionTotal: formulePrice || 0,
                    transactionShipping: 0,
                    lpAwin: offerCode === "539" ? "professionel" : "particulier"
                }
            }
        }

        TagManager.dataLayer(tagManagerArgs);
    })
}
